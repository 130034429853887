import React, { useState } from "react";

export default function ({ color = "#FFFFFF", onChange = () => {}, ...props }) {
  const colorVal = color.replace(/[^A-Fa-f0-9]+/gi, "");
  const [value, setValue] = useState(colorVal);

  const [isFocused, setFocused] = useState(false);

  if (!isFocused && value !== colorVal) {
    setValue(colorVal);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "stretch",
        alignItems: "center",
      }}
    >
      <span
        style={{
          color: "#BBB",
          fontWeight: "bold",
          marginRight: ".1em",
          fontSize: "24px",
        }}
      >
        #
      </span>
      <input
        type="text"
        value={value}
        pattern="[A-Fa-f0-9]{6}"
        minLength="6"
        maxLength="6"
        onFocus={() => setValue("") || setFocused(true)}
        style={{
          width: "100%",
          appearance: "none",
          fontFamily: "inherit",
          fontSize: "24px",
          fontWeight: "bold",
          color: "#CCC",
          border: "none",
          border: "solid transparent 2px",
          borderColor: isFocused ? "#888888" : "transparent",
          borderBottomColor: isFocused ? "#888888" : "#CCCCCC",
          background: "transparent",
          outline: "none",
          margin: "0 2px",
          padding: "4px",
        }}
        onChange={(e) => {
          let formatted = e.target.value.replace(/[^A-Fa-f0-9]+/gi, "");
          setValue(formatted);
        }}
        onBlur={(e) => {
          if (e.target.validity.valid && e.target.value) {
            onChange(e.target.value);
          } else {
            setValue(colorVal.replace(/[^A-Fa-f0-9]+/gi, ""));
          }

          setFocused(false);
        }}
      />
    </div>
  );
}
