import React, { useEffect, useState, useCallback } from "react";
import convert from "color-convert";
import { ContrastChecker } from "../../utils";
//import { ColorConverter } from "../../utils";

import _ from "lodash";

export default function ({
  /*hue,
  saturation = 1,
  value = 1,*/
  width = 240,
  height = 240,
  hsv = [100, 100, 100],
  contrastHsv,
  contrastRatios = [],
  onChange = (s, v) => {},
}) {
  const [isMouseDown, setMouseDown] = useState(false);
  const canvas = React.useRef();

  const [hue, saturation, value] = hsv;

  const contrastRgb = contrastHsv && convert.hsv.rgb(contrastHsv);

  const setSatValByMouseEvent = (e) => {
    let rect = canvas.current.getBoundingClientRect();

    let currentX = e.clientX - rect.left;
    let currentY = e.clientY - rect.top;

    let sat = ((currentX / rect.width) * 100) / 100;
    let val = ((1 - currentY / rect.height) * 100) / 100;

    sat = Math.max(0.0, Math.min(1.0, sat)) * 100;
    val = Math.max(0.0, Math.min(1.0, val)) * 100;

    onChange([hue, sat, val]);
  };

  const setSatValByMouseEventThrottled = useCallback(
    _.throttle((e) => {
      let rect = canvas.current.getBoundingClientRect();

      let currentX = e.clientX - rect.left;
      let currentY = e.clientY - rect.top;

      let sat = ((currentX / rect.width) * 100) / 100;
      let val = ((1 - currentY / rect.height) * 100) / 100;

      sat = Math.max(0.0, Math.min(1.0, sat)) * 100;
      val = Math.max(0.0, Math.min(1.0, val)) * 100;

      onChange([hue, sat, val]);
    }, 100),
    [hue]
  );

  const draw = () => {
    let target = canvas.current;
    let context = target.getContext("2d");

    let gradientData = context.createImageData(width, height);

    let ratioRef = contrastRatios.map(() => null);

    for (var x = 0; x < width; x++) {
      ratioRef = contrastRatios.map(() => null);

      for (var y = 0; y < height; y++) {
        // Get the pixel index
        var pixelIndex = (y * width + x) * 4;

        //var rgb = ColorConverter.hsvToRgb(hue / 360, x / width, 1 - y / height);

        const sat = (x / width) * 100;
        const val = (1 - y / height) * 100;

        const rgb = convert.hsv.rgb([hsv[0], sat, val]);

        const contrastRatio =
          rgb && contrastRgb
            ? ContrastChecker.contrast(rgb, contrastRgb)
            : null;

        let newRatioRef = [];

        if (contrastRatio) {
          newRatioRef = contrastRatios.map((ratio) => {
            if (ratio < contrastRatio) {
              return -1;
            }

            if (ratio > contrastRatio) {
              return 1;
            }

            return 0;
          });
        }

        const [r, g, b] = rgb;

        // Set the pixel data
        gradientData.data[pixelIndex] = r; // Red
        gradientData.data[pixelIndex + 1] = g; // Green
        gradientData.data[pixelIndex + 2] = b; // Blue
        gradientData.data[pixelIndex + 3] = 255; // Alpha

        ratioRef.forEach((ratio, index) => {
          if (ratio !== null && ratio != newRatioRef[index]) {
            gradientData.data[pixelIndex] = 255; // Red
            gradientData.data[pixelIndex + 1] = 255; // Green
            gradientData.data[pixelIndex + 2] = 255; // Blue
            gradientData.data[pixelIndex + 3] = 255; // Alpha
          }
        });

        /*if (contrastRatios > 4.485 && contrastRatios < 4.515) {
          gradientData.data[pixelIndex] = 255; // Red
          gradientData.data[pixelIndex + 1] = 255; // Green
          gradientData.data[pixelIndex + 2] = 255; // Blue
          gradientData.data[pixelIndex + 3] = 255; // Alpha
        }*/

        ratioRef = newRatioRef;
      }
    }

    context.putImageData(gradientData, 0, 0);
    context.imageSmoothingEnabled = false;
  };

  const throttleDraw = _.throttle(
    useCallback(draw, [
      width,
      height,
      contrastRatios,
      hsv,
      contrastHsv,
      canvas,
    ]),
    100
  );

  //const throttleDraw = draw;

  // Component Did Mount
  useEffect(() => {
    const onMouseDown = (e) => {
      setMouseDown(true);
      setSatValByMouseEvent(e);
    };

    const onMouseMove = (e) => {
      if (isMouseDown) {
        setSatValByMouseEventThrottled(e);
      }
    };

    const onMouseUp = () => {
      setMouseDown(false);
    };

    let target = canvas.current;
    target.addEventListener("mousedown", onMouseDown);
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
    //draw();

    throttleDraw();

    // Clean up
    return () => {
      target.removeEventListener("mousedown", onMouseDown);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [canvas, height, width, isMouseDown, setMouseDown, onChange, hue]);

  let pointerSize = 36;

  return (
    <>
      <div
        style={{
          width: `${width}px`,
          position: "relative",
          userSelect: "none",
          borderRadius: "8px",
          //overflow: "hidden",
          //border: "solid white 3px",
        }}
      >
        <canvas
          ref={canvas}
          className="color-picker"
          width={width}
          height={height}
          style={{
            width: "100%",
            height: "auto",
            borderRadius: "8px",
            //filter: "blur(1px)",
          }}
        ></canvas>
        <div
          style={{
            position: "absolute",
            display: "block",
            width: `${pointerSize}px`,
            height: `${pointerSize}px`,
            //background: "black",
            //background: ColorConverter.hsvToWebColor(hue, saturation, value).hex,
            /*background: ColorConverter.hsvToWebColor(hue, saturation, value)
              .hex,*/
            backgroundColor: `#${convert.hsv.hex([hue, saturation, value])}`,
            boxShadow: "0 0 6px rgba(51, 51, 51, .2)",
            border: "solid white 3px",
            boxSizing: "border-box",
            borderRadius: "50%",
            top: `${100 - value}%`,
            left: `${saturation}%`,
            /*transition: isMouseDown
              ? "top .2s ease-out, left .2s ease-out"
              : "none",*/
            pointerEvents: "none",
            transform: "translate(-50%, -50%)",
          }}
        ></div>
      </div>
    </>
  );
}
