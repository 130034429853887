import React, { useEffect, useState } from "react";
import convert from "color-convert";

//import Pointer from './pointer';

export default function ({
  hsv = [100, 100, 100],
  width = 240,
  height = 240,
  onChange = () => {},
  maskColor,
}) {
  let canvas = React.useRef();
  let container = React.useRef();

  const [hue, saturation, value] = hsv;

  const [initialized, setInitialized] = useState(false);

  const [isMouseDown, setMouseDown] = useState(false);

  useEffect(() => {
    const setHueByMouseEvent = (e) => {
      let rect = canvas.current.getBoundingClientRect();

      let currentX = e.clientX - rect.left - rect.width / 2;
      let currentY = e.clientY - rect.top - rect.height / 2;

      /*let angle =
        -parseInt((Math.atan2(currentY, currentX) * 180) / Math.PI) % 360;*/

      let angle = -((Math.atan2(currentY, currentX) * 180) / Math.PI) % 360;

      while (angle < 0) {
        angle += 360;
      }

      if (angle !== hue) {
        onChange([angle, saturation, value]);
      }
    };

    const onMouseDown = (e) => {
      setMouseDown(true);
      setHueByMouseEvent(e);
    };

    const onMouseMove = (e) => {
      if (isMouseDown) {
        setHueByMouseEvent(e);
      }
    };

    const onMouseUp = () => {
      if (isMouseDown) {
        setMouseDown(false);
      }
    };

    const draw = () => {
      let target = canvas.current;
      let context = target.getContext("2d");

      if (!initialized) {
        var radius = width / 2;
        var toRad = (2 * Math.PI) / 360;
        var step = 1 / radius;

        for (var i = 0; i < 360; i += step) {
          var rad = -i * toRad;
          context.strokeStyle = "hsl(" + i + ", 100%, 50%)";
          context.beginPath();
          context.moveTo(radius, radius);
          context.lineTo(
            radius + radius * Math.cos(rad),
            radius + radius * Math.sin(rad)
          );
          context.stroke();
        }

        context.fillStyle = "rgb(255, 255, 255)";
        context.beginPath();
        context.arc(radius, radius, 0, 0, Math.PI * 2, true);
        context.closePath();
        context.fill();

        var grd = context.createRadialGradient(
          radius,
          radius,
          0,
          radius,
          radius,
          radius
        );
        grd.addColorStop(0, "rgba(255,255,255, 1.0");
        grd.addColorStop(1, "rgba(255,255,255, 0.0");
        context.fillStyle = grd;
        context.fillRect(0, 0, radius * 2, radius * 2);

        setInitialized(true);
      }
    };

    let target = container.current;
    target.addEventListener("mousedown", onMouseDown);
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
    draw();

    return () => {
      target.removeEventListener("mousedown", onMouseDown);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [
    isMouseDown,
    setMouseDown,
    initialized,
    hue,
    onChange,
    width,
    height,
    saturation,
    value,
  ]);

  //let pointerSize = 16;

  return (
    <>
      <div
        ref={container}
        className="hue-picker"
        style={{
          width: `${width}px`,
          height: `${height}px`,
          position: "relative",
          userSelect: "none",
        }}
      >
        <canvas
          style={{
            borderRadius: "50%",
            overflow: "hidden",
          }}
          ref={canvas}
          width={width}
          height={height}
        ></canvas>

        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            display: "block",
            width: `${width - 24}px`,
            height: `${height - 24}px`,
            backgroundColor: maskColor,
            borderRadius: "50%",
            transform: "translate(-50%, -50%)",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            borderRadius: "50%",
            width: "90px",
            height: "90px",
            backgroundColor: "#FFF",
            transform: `translate(-50%, -50%)`,
            display: "none",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            bottom: "50%",
            left: "50%",
            height: "0",
            width: "50%",
            borderBottom: "solid #888 1px",
            transform: `translate(0, -50%) rotate(${-hue}deg)`,
            transformOrigin: "bottom left",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            borderRadius: "50%",
            border: "solid white 6px",
            width: "80px",
            height: "80px",
            backgroundColor: `#${convert.hsv.hex([hue, saturation, value])}`,
            transform: `translate(-50%, -50%)`,
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            borderRadius: "50%",
            border: "solid white 3px",
            width: "36px",
            height: "36px",
            backgroundColor: `#${convert.hsv.hex([hue, 100, 100])}`,
            transform: `translate(-50%, -50%) rotate(${-hue}deg) translateX(${
              width / 2 - 4
            }px)`,
            boxShadow: "0 0 6px rgba(51, 51, 51, .2)",
          }}
        ></div>
      </div>
    </>
  );
}
