const ContrastLevelIndicator = ({ contrast, levels = [] }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "24px",
        }}
      >
        {contrast && (
          <div
            style={{
              color: "#CCCCCC",
              fontSize: "48px",
              fontWeight: "bold",
              order: 2,
            }}
          >
            {contrast}
          </div>
        )}
        <div>
          {levels.map(({ label, level, tag }) => (
            <div
              style={{
                color: "#CCCCCC",
                opacity: level <= contrast ? 1.0 : 0.3,
                fontSize: "36px",
                fontWeight: "bold",
                display: "flex",
                flexDirection: "row",
                alignItems: "baseline",
              }}
            >
              {label}
              {tag && (
                <span style={{ fontSize: "50%", marginLeft: "4px" }}>
                  {" "}
                  &nbsp;({tag})
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ContrastLevelIndicator;
