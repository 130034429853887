import React, { useState, useEffect, useCallback } from "react";
import convert from "color-convert";

import {
  ContrastLevelIndicator,
  ColorPicker,
  HuePicker,
  ColorControl,
  TextPreview,
} from "./components";

import _ from "lodash";
import { createBrowserHistory } from "history";

import { ContrastChecker } from "./utils";

import "./App.scss";

function App() {
  const history = createBrowserHistory();

  const [color1, color2] = history.location.hash.replace("#", "").split("/");

  //const [hsv, setHsv] = useState({ h: 300, s: 0.2, v: 0.25 });
  const [hsv, setHsv] = useState(
    color1 ? convert.hex.hsv(color1) : [300, 20, 25]
  );
  //const [hsv2, setHsv2] = useState({ h: 45, s: 0.25, v: 0.9 });
  const [hsv2, setHsv2] = useState(
    color2 ? convert.hex.hsv(color2) : [45, 25, 90]
  );

  const updateHistory = () => {
    history.replace(`#${convert.hsv.hex(hsv)}/${convert.hsv.hex(hsv2)}`);
  };

  const updateHistoryThrottled = useCallback(
    _.debounce((hsv, hsv2) => {
      history.replace(`#${convert.hsv.hex(hsv)}/${convert.hsv.hex(hsv2)}`);
    }, 100),
    []
  );

  const updateHsv1 = (color) => {
    setHsv(color);
    updateHistoryThrottled(color, hsv2);
  };

  const updateHsv2 = (color) => {
    setHsv2(color);
    updateHistoryThrottled(hsv, color);
  };

  useEffect(() => {
    updateHistory();
    window.onhashchange = () => {
      const [color1, color2] = window.location.hash.replace("#", "").split("/");

      if (color1) {
        updateHsv1(convert.hex.hsv(color1));
      }

      if (color2) {
        updateHsv2(convert.hex.hsv(color2));
      }
    };
  }, []);

  return (
    <div className="App">
      {/*<dl>
        {hsv && (
          <>
            <dt>Color 1</dt>
            <dd>
              <ColorControl
                color={convert.hsv.hex(hsv)}
                onChange={(rgb) => updateHsv1(convert.hex.hsv(rgb))}
              />
            </dd>
          </>
        )}
        {hsv2 && (
          <>
            <dt>Color 2</dt>
            <dd>
              <ColorControl
                color={convert.hsv.hex(hsv2)}
                onChange={(rgb) => updateHsv2(convert.hex.hsv(rgb))}
              />
            </dd>
          </>
        )}
        </dl>*/}
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "32px",
            margin: "0 auto",
            width: "100%",
            minWidth: "1260px",
            maxWidth: "1440px",
          }}
        >
          <div style={{ margin: "32px" }}>
            <div style={{ marginBottom: "32px" }}>
              <ColorControl
                color={convert.hsv.hex(hsv)}
                onChange={(rgb) => updateHsv1(convert.hex.hsv(rgb))}
              />
            </div>
            <div style={{ marginBottom: "32px" }}>
              <ColorPicker
                hsv={hsv}
                contrastHsv={hsv2}
                contrastRatios={[3, 4.5, 7]}
                onChange={updateHsv1}
              />
            </div>
            <HuePicker hsv={hsv} maskColor="#222225" onChange={updateHsv1} />
          </div>
          <div style={{ margin: "32px" }}>
            <div style={{ marginBottom: "32px" }}>
              <ColorControl
                color={convert.hsv.hex(hsv2)}
                onChange={(rgb) => updateHsv2(convert.hex.hsv(rgb))}
              />
            </div>
            <div style={{ marginBottom: "32px" }}>
              <ColorPicker
                hsv={hsv2}
                onChange={updateHsv2}
                contrastHsv={hsv}
                contrastRatios={[3, 4.5, 7]}
              />
            </div>
            <HuePicker hsv={hsv2} maskColor="#222225" onChange={updateHsv2} />
          </div>
          {hsv && hsv2 && (
            <div style={{ margin: "32px" }}>
              <div>
                <ContrastLevelIndicator
                  contrast={
                    Math.round(
                      ContrastChecker.contrast(
                        convert.hsv.rgb(hsv),
                        convert.hsv.rgb(hsv2)
                      ) * 100
                    ) / 100
                  }
                  levels={[
                    {
                      label: "AA",
                      level: 3.0,
                      tag: "Large Text",
                    },
                    {
                      label: "AA",
                      level: 4.5,
                    },
                    {
                      label: "AAA",
                      level: 4.5,
                      tag: "Large Text",
                    },
                    {
                      label: "AAA",
                      level: 7.0,
                    },
                  ]}
                />
              </div>
              <div
                style={{
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                <TextPreview
                  color={`#${convert.hsv.hex(hsv2)}`}
                  background={`#${convert.hsv.hex(hsv)}`}
                />
                <TextPreview
                  color={`#${convert.hsv.hex(hsv)}`}
                  background={`#${convert.hsv.hex(hsv2)}`}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
